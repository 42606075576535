import clsx from "clsx";
import Apple from "~/assets/svg/Apple";
import Google from "~/assets/svg/Google";
import { colors } from "~/styles";

const Download = () => {
  const iOSUrl = "https://apple.co/3G48PAU";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=co.zestapp.mobile&hl=en_US&gl=US";

  return (
    <div className="flex gap-4 py-9">
      <a
        href={iOSUrl}
        className={clsx([
          "ml-auto inline-flex w-40 items-center rounded px-3.5 py-1.5 transition-opacity hover:opacity-80",
          "xl:w-72 xl:rounded-md xl:px-6 xl:py-3",
        ])}
        style={{
          backgroundColor: colors.black.main,
        }}
      >
        <span className={clsx(["mr-2.5 block h-8", "xl:mr-6 xl:h-12"])}>
          <Apple
            width="auto"
            height="100%"
          />
        </span>
        <span className="font-apercu text-white">
          <span className="block text-xs xl:text-xl">Download on the</span>
          <span className="block xl:text-3xl">App Store</span>
        </span>
      </a>
      <a
        href={androidUrl}
        className={clsx([
          "mr-auto inline-flex w-40 items-center rounded px-3.5 py-1.5 transition-opacity hover:opacity-80",
          "xl:w-72 xl:rounded-md xl:px-6 xl:py-3",
        ])}
        style={{
          backgroundColor: colors.black.main,
        }}
      >
        <span className={clsx(["mr-2.5 block h-8", "xl:mr-6 xl:h-12"])}>
          <Google
            width="auto"
            height="100%"
          />
        </span>
        <span className="font-apercu text-white">
          <span className="block text-xs xl:text-xl">Get it on</span>
          <span className="block xl:text-3xl">Google Play</span>
        </span>
      </a>
    </div>
  );
};

export default Download;
